<template>
  <div class="main" @click="fnLogin">
    <img src="../../assets/image/dingSku.png" class="ding-sku" />
  </div>
</template>
<script>
import { openTryoutSku } from "dingtalk-design-libs";
import openLink from "dingtalk-jsapi/api/biz/util/openLink";
import { getQueryVariable } from "@/utils/tools.js";
export default {
  setup() {
    const corpId = getQueryVariable("corpId");
    const token = getQueryVariable("purchaseToken");

    const ua =
      navigator && navigator.userAgent
        ? navigator.userAgent.toLowerCase() || ""
        : "";
    const isDingding = /dingtalk/i.test(ua);

    const fnLogin = () => {
      if (isDingding) {
        openTryoutSku({
          corpId: corpId,
          appId: "123599",
          token: token,
        })
          .then((res) => {
            const {
              // action的值为：
              // 'ok'，用户执行了开通动作，或将自己加入了已开通的应用的可见范围内
              // 'cancel', 用户点击了取消按钮
              // 'unknown'，用户点击空白区域关闭了弹窗，此时可以跟cancel采取同样的处理逻辑
              action,
              // 开通了应用的组织的corpId。因为个人开通可能会用钉钉的隐藏组织，所以开通应用的组织的corpId以这里返回的为准。
              corpId,
            } = res;
            if (action === "ok") {
              openLink({ url: `${location.origin}/?corpid=${corpId}` });
            }
          })
          .catch(() => {
            // 钉钉侧出现了技术异常，比如打开弹窗失败等，出现概率非常低
          });
      }
    };

    fnLogin();

    return {
      fnLogin,
    };
  },
};
</script>

<style scoped>
.ding-sku {
  width: 100%;
}
</style>
